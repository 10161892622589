const $ = jQuery;

export default class BeforeAndAfterSliders {
    constructor(options = null) {}

    init() {
        this.__beforeAndAfter();
    }

    __beforeAndAfter() {
        const $beforeAndAfterElms = $(".js-before-and-after");
        const startWidth = 8.87;

        $beforeAndAfterElms.each(function () {
            var after = $(this).find(".js-after");
            var handle = $(this).find(".js-handle");
            var sliderInput = $(this).find(".js-slider-input");

            // Reset sliders on setup
            sliderInput.val(startWidth);
            after.css("width", sliderInput.val() + "%");
            handle.css("width", sliderInput.val() + "%");

            // Event handler for sliding
            sliderInput.on("input", function () {
                var value = $(this).val();
                after.css("width", value + "%");
                handle.css("width", value + "%");
            });

            // Custom event handler for reseting values/positions
            // sliderInput.on("reset", function () {
            //     sliderInput.val(startWidth);
            //     after.css("width", sliderInput.attr("data-start-value") + "%");
            //     handle.css("width", sliderInput.attr("data-start-value") + "%");
            // });
        });
    }
}
