// import js dependencies
import AjaxPost from "./ajax";
import Swipers from "./swiper";
import Menu from "./menu";
import BeforeAndAfterSliders from "./before-after";

//Get Ajax Responses
jQuery(($) => {
    const swipers = new Swipers({});
    swipers.init();
    // const ajaxPost = new AjaxPost({});
    // ajaxPost.init();
    const menu = new Menu({});
    menu.init();
});

jQuery(function ($) {
    //Consent Magic footer pop-up
    $("body").on("click", ".tessellateConsent", function (event) {
        event.preventDefault();
        $(".cs-info-sticky-button").click();
    });
});

jQuery(($) => {
    const beforeAndAfterSliders = new BeforeAndAfterSliders({});
    beforeAndAfterSliders.init();
});
