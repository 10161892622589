import Swiper from "swiper/bundle";
import "swiper/css/bundle";

const $ = jQuery;

export default class Swipers {
    constructor(options = null) {}

    init() {
        this.__swiper1(); // treatments
        this.__swiper2(); // brands
        this.__swiper3(); // accreditations
        this.__swiper4(); // testimonials
        this.__swiper5(); // before & after
    }

    __swiper1() {
        const $swiperBlocks = $(".js-treatments-swiper");

        for (const swiperBlock of $swiperBlocks) {
            const swiperBlockId = $(swiperBlock).attr("id");

            // console.log(swiperBlockId);

            const swiper = new Swiper("#" + swiperBlockId, {
                speed: 200,
                slidesPerView: 4,
                spaceBetween: 20,
                autoplay: false,
                loop: false,
                navigation: {
                    nextEl: ".js-slider-right-" + swiperBlockId,
                    prevEl: ".js-slider-left-" + swiperBlockId,
                },
                breakpoints: {
                    // when window width is >= 320px
                    320: {
                        slidesPerView: 1,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    1280: {
                        slidesPerView: 3,
                    },
                    1920: {
                        slidesPerView: 4,
                    },
                },
            });
        }
    }

    __swiper2() {
        const $swiperBlocks = $(".js-brands-swiper");

        for (const swiperBlock of $swiperBlocks) {
            const swiperBlockId = $(swiperBlock).attr("id");

            // console.log(swiperBlockId);

            const swiper = new Swiper("#" + swiperBlockId, {
                speed: 200,
                slidesPerView: 4,
                spaceBetween: 20,
                autoplay: false,
                loop: false,
                navigation: {
                    nextEl: ".js-slider-right-" + swiperBlockId,
                    prevEl: ".js-slider-left-" + swiperBlockId,
                },
                breakpoints: {
                    // when window width is >= 320px
                    320: {
                        slidesPerView: 1,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    1280: {
                        slidesPerView: 3,
                    },
                    1920: {
                        slidesPerView: 4,
                    },
                },
            });
        }
    }

    __swiper3() {
        const $swiperBlocks = $(".js-accreditations-swiper");

        for (const swiperBlock of $swiperBlocks) {
            const swiperBlockId = $(swiperBlock).attr("id");

            // console.log(swiperBlockId);

            const swiper = new Swiper("#" + swiperBlockId, {
                speed: 400,
                slidesPerView: 5,
                autoplay: true,
                loop: true,
                breakpoints: {
                    // when window width is >= 320px
                    320: {
                        slidesPerView: 2,
                        spaceBetween: 75,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 75,
                    },
                    991: {
                        slidesPerView: 4,
                        spaceBetween: 75,
                    },
                    1280: {
                        slidesPerView: 5,
                        spaceBetween: 75,
                    },
                },
            });
        }
    }

    __swiper4() {
        const $swiperBlocks = $(".js-testimonials-swiper");

        for (const swiperBlock of $swiperBlocks) {
            const swiperBlockId = $(swiperBlock).attr("id");

            // console.log(swiperBlockId);

            const swiper = new Swiper("#" + swiperBlockId, {
                speed: 400,
                slidesPerView: 1,
                autoplay: true,
                loop: true,
                pagination: {
                    el: ".js-pagination-" + swiperBlockId,
                    clickable: true,
                    renderBullet: function (index, className) {
                        return (
                            '<div class="' +
                            className +
                            '"><span class="slide-no">' +
                            (index + 1) +
                            "</span></div>"
                        );
                    },
                },
            });
        }
    }

    __swiper5() {
        const $swiperBlocks = $(".js-before-after-swiper");

        for (const swiperBlock of $swiperBlocks) {
            const swiperBlockId = $(swiperBlock).attr("id");

            const swiper = new Swiper("#" + swiperBlockId, {
                effect: "fade",
                fadeEffect: {
                    crossFade: true,
                },
                speed: 800,
                slidesPerView: 1,
                autoplay: false,
                loop: true,
                allowTouchMove: false,
                preventClicks: true,
                navigation: {
                    nextEl: ".js-slider-right-" + swiperBlockId,
                    prevEl: ".js-slider-left-" + swiperBlockId,
                },
                pagination: {
                    el: ".js-pagination-" + swiperBlockId,
                    clickable: true,
                    renderBullet: function (index, className) {
                        return (
                            '<div class="' +
                            className +
                            '"><span class="slide-no">' +
                            (index + 1) +
                            "</span></div>"
                        );
                    },
                },
            });
        }

        // Event registration for emitting "reset"
        // $(".js-before-after-slides-parent").each(function () {
        //     var sliderControls = $(this).find(".slider-controls");

        //     sliderControls.each(function () {
        //         var arrows = $(this).find(".arrow");
        //         arrows.each(function () {
        //             $(this).on("click", function () {
        //                 //$(".js-slider-input").trigger("reset");
        //             });
        //         });
        //     });
        // });
    }
}
